import React, { Component } from 'react';
import { TextInput, Select, Row, Card, Col }  from 'react-materialize';
import taxProfits from './libs/taxProfits';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {amount: '',
                  amountType: 'brutto',
                  vatType: 0.23,
                  automotive: 'no',
                  profitVat: 0,
                  profitIncome: 0,
                  expense: 0
                  };

    this.handleChange = this.handleChange.bind(this);
  }

  calculate() {
    this.setState(taxProfits(this.state.amount, this.state.amountType, this.state.vatType, this.state.automotive));
  }

  handleChange(e) {
    let change = {}

    if (e.target.type === 'checkbox') {
      change[e.target.name] = e.target.checked;
    } else {
      change[e.target.name] = e.target.value;
    }

    this.setState(change, () => this.calculate() );

  }

  render() {
    return (
      <div className="App">
        <Row>
          <Col s={12} m={6} l={6} className="offset-m3 offset-l3">
            <Card
              className="teal lighten-2"
              textClassName="white-text"
              title="Kalkulator przedsiębiorcy"
            >
            Oblicza realny wydatek po "wrzuceniu" kosztu na firmę
            </Card>
          </Col>
        </Row>
        <Row>
          <Col s={12} m={6} l={6} className="offset-m3 offset-l3">
            <TextInput s={12} type="number" name="amount" value={this.state.amount} placeholder="Kwota" label="Kwota" onChange={this.handleChange}/>
          </Col>  
        </Row>
        <Row>
        <Col s={12} m={6} l={6} className="offset-m3 offset-l3">
          <Select s={12} name='amountType' type='select' label="" defaultValue='brutto' onChange={this.handleChange}>
            <option value='brutto'>Brutto</option>
            <option value='netto'>Netto</option>
          </Select>
          </Col>
        </Row>
        <Row>
          <Col s={12} m={6} l={6} className="offset-m3 offset-l3">
            <Select s={12} name='vatType' type='select' label="Stawka VAT" defaultValue='0.23' onChange={this.handleChange}>
              <option value='0.23'>23%</option>
              <option value='0.08'>8%</option>
              <option value='0.07'>5%</option>
              <option value='0.00'>0%</option>
            </Select>
          </Col>
        </Row>
        <Row>
          <Col s={12} m={6} l={6} className="offset-m3 offset-l3">
            <Select s={12} name='automotive' type='select' label="Wydatek samochodowy" defaultValue='no' onChange={this.handleChange}>
              <option value='no'>Nie</option>
              <option value='yes'>Tak</option>
            </Select>
          </Col>
        </Row>
        <Row>
          <Col s={12} m={6} l={6} className="offset-m3 offset-l3">
            <Card horizontal>
              <p>Zysk na podatku VAT: <strong>{this.state.profitVat}</strong></p>
              <p>Zysk na podatku dochodowym: <strong>{this.state.profitIncome}</strong></p>
              <p>Realnie poniesiony wydatek: <strong>{this.state.expense}</strong></p>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default App;
