/**
amont - kwota wprowadzona
amountType - brutto/netto
vatType - stawka VAT ex. '0.23'
automotive - czy wydatek samochodowy

Od stycznia 2019 roku przedsiębiorca Marek weźmie w leasing operacyjny samochód osobowy, którego wartość wyniesie 200 000 zł. Jest on czynnym podatnikiem VAT. Otrzyma w styczniu fakturę dokumentującą opłatę wstępną na wartość 10 000 zł netto oraz 2300 VAT. Jaką wartość z tejże faktury będzie mógł ująć w kosztach podatkowych?

Pan Marek będzie mógł ująć w kosztach podatkowych wartość 8362,50 zł, w związku z ograniczeniem – limitem.

Obliczenia:
(150 000 zł / 200 000 zł) = 0,75 – proporcja ujęcia wydatku w kosztach
0,75 * [10 000 zł + (2300 zł * 50%)] = 8362,50 zł – proporcja pomnożona przez (wartość opłaty wstępnej + 50% wartości podatku VAT)

Zobacz więcej: https://poradnikprzedsiebiorcy.pl/-zasady-rozliczania-samochodow-od-2019
===============================================================================
https://www.podatki.biz/forum/index.php?org=browse&poz=0&id=310469&foid=7&pnf=1

Dotychczas (do 31.12.2018) było tak: 
Odliczenie vat 50%, czyli: 4,67. 
W koszty: 40,65 + 4,68 = 45,33 

Teraz od 1.01.2019 jest tak: 
Odliczenie vat 50%, czyli: 4,67. 
W koszty: 40,65 + 4,68 = 45,33; 45,33 x 75% = 34 zł.
=============
do 31.12.2018
**/
export default function taxProfits (amount, amountType, vatType, automotive){
  let nettoPrice, profitVat, bruttoPrice, expense;

  if (amountType === 'brutto') {
    bruttoPrice = amount;
    nettoPrice = (amount / (1 + Number(vatType) )).toFixed(2);
    profitVat = (amount - nettoPrice).toFixed(2);
  } else {
    bruttoPrice = (amount*(1 + Number(vatType) ) ).toFixed(2);
    nettoPrice = amount;
    profitVat = (nettoPrice * Number(vatType)).toFixed(2);
  }

  let profitIncome = (0.18 * nettoPrice).toFixed(2);

  expense = (nettoPrice - profitIncome).toFixed(2);

  if (automotive === 'yes') {
    profitVat = (profitVat/2).toFixed(2);
    profitIncome = ( ( (bruttoPrice - profitVat)*0.75)*0.18).toFixed(2);
    expense = (bruttoPrice - profitVat - profitIncome).toFixed(2);
  }


  return {
    profitVat,
    profitIncome,
    expense
  }
};
